import { computed, defineComponent, reactive, ref } from 'vue';
import { Button } from 'vant';
import StageButton from '../../components/stage-button/index.vue';
import { AppViewState } from '@/hooks/use-app';
export default defineComponent({
  props: {
    isPlaying: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 0
    },
    currentTime: {
      type: Number,
      default: 0
    }
  },
  components: {
    StageButton,
    [Button.name]: Button
  },
  setup(props, {
    emit
  }) {
    const progressBarRef = ref(null);
    const onSeek = () => {
      emit('seek');
    };
    const onSkip = () => {
      emit('skip');
    };
    const timeFormatter = time => {
      let minutes = Math.floor(time / 60);
      let seconds = Math.floor(time % 60);
      const minStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const secStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minStr}:${secStr}`;
    };
    const progress = computed(() => {
      return props.currentTime / props.duration * 100;
    });
    const startPos = ref(0);
    const seekStartTimestamp = ref(0);
    const state = reactive({
      isDragging: false,
      transformX: 0
    });
    const onTouchMove = e => {
      const {
        clientX,
        clientY
      } = e.touches[0];
      let diff = 0;
      if (AppViewState.deviceDirection === 'portrait') {
        diff = clientY - startPos.value;
      } else {
        diff = clientX - startPos.value;
      }
      const totalWidth = progressBarRef.value?.clientWidth || 0;
      if (!totalWidth) return;
      const percent = diff / totalWidth;
      const newTime = seekStartTimestamp.value + percent * props.duration;
      emit('seek', newTime);
    };
    const onTouchEnd = e => {
      console.log('onMouseUp');
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
      state.isDragging = false;
    };
    const onTouchStart = e => {
      console.log('onMouseDown');
      const {
        clientX,
        clientY
      } = e.touches[0];
      if (AppViewState.deviceDirection === 'portrait') {
        startPos.value = clientY;
      } else {
        startPos.value = clientX;
      }
      state.isDragging = true;
      seekStartTimestamp.value = props.currentTime;
      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onTouchEnd);
    };
    const dotTransformX = computed(() => {
      if (!progressBarRef.value) return 0;
      return props.currentTime / props.duration * progressBarRef.value.clientWidth;
    });
    const toggle = () => {
      if (props.isPlaying) {
        emit('pause');
      } else {
        emit('play');
      }
    };
    const handleSeekClick = e => {
      const totalWidth = progressBarRef.value?.clientWidth || 0;
      if (!totalWidth) return;
      const percent = e.offsetX / totalWidth;
      const newTime = percent * props.duration;
      emit('seek', newTime);
    };
    return {
      onSkip,
      onSeek,
      timeFormatter,
      progress,
      progressBarRef,
      dotTransformX,
      onTouchStart,
      toggle,
      handleSeekClick
    };
  }
});