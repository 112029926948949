import { defineComponent } from 'vue';
import { Button } from 'vant';
import { useRouter } from 'vue-router';
import StageButton from '../../components/stage-button/index.vue';
export default defineComponent({
  components: {
    StageButton,
    [Button.name]: Button
  },
  setup() {
    const router = useRouter();
    const goHome = () => {
      router.replace('/');
    };
    return {
      goHome
    };
  }
});