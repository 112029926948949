import { defineComponent } from 'vue';
import { Button } from 'vant';
export default defineComponent({
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Button
  },
  setup(props, {
    emit
  }) {
    const onClick = () => {
      emit('click');
    };
    return {
      onClick
    };
  }
});