import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BgImage',
  props: {
    className: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: '/image/placeholder.png'
    },
    width: {
      type: Number,
      default: 1280
    }
  },
  setup(props) {
    let url = '';
    if (props.src.match('aliyuncs')) {
      if (props.src.match('x-oss-process')) {
        url = props.src;
      } else {
        url = `${props.src}?x-oss-process=image/format,jpg/resize,limit_1,w_${props.width}`;
      }
    } else {
      url = props.src;
    }
    return {
      url
    };
  }
});