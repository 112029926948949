import "core-js/modules/es.array.push.js";
import { defineComponent, onMounted, reactive, toRef } from 'vue';
import { Col, Row, Button } from 'vant';
import { useRoute, useRouter } from 'vue-router';
import { AppChapterState } from '../../stage';
import VideoPreload from '@/components/video-preload/index.vue';
import IframeContainer from '@/components/iframe-container/index.vue';
import VideoContainer from '@/components/video-container/index.vue';
import TrackTrigger from '@/components/track-trigger/index.vue';
import NextSide from '@/components/next-side/index.vue';
import { AppStageState } from '@/hooks/use-app';
import { computed } from 'vue';
import { viewChapter } from '@/api/app';
export default defineComponent({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    VideoPreload,
    IframeContainer,
    VideoContainer,
    TrackTrigger,
    NextSide
  },
  setup() {
    console.log(AppChapterState.list);
    const router = useRouter();
    const route = useRoute();
    const stageId = route.params.sid.toLowerCase();
    const stage = AppChapterState.list.find(s => s.id === stageId);
    AppStageState.current = stage;
    const state = reactive({
      enablePreload: false
    });
    const stageChildren = AppChapterState.list.filter(s => s.pid === stageId);
    const onVideoTrans = () => {
      console.log('onVideoTrans');
      state.enablePreload = true;
    };
    onMounted(() => {
      if (stage) {
        //@ts-ignore
        viewChapter(stage.key).then(() => {
          //
        }).catch(() => {
          //
        });
      }
    });
    const preloadVideoUrls = stageChildren.filter(s => s.type === 'video' && s.url).map(s => s.url);
    const bgColorStyle = computed(() => {
      return {
        background: '#000'
      };
    });
    const jump = stage => {
      router.push(`/stage/${stage.id}`);
    };
    return {
      stage,
      isVideo: stage?.type === 'video',
      isIframe: stage?.type === 'iframe',
      stageChildren,
      jump,
      onVideoTrans,
      enablePreload: toRef(state, 'enablePreload'),
      bgColorStyle,
      preloadVideoUrls,
      nextOptions: stageChildren
    };
  }
});