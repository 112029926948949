import { GlobalConfig } from "@/config"
import { reactive } from "vue"

export type StageItem = {
    pid: string
    id: string
    title: string | string[]
    type: string
    url: string
    poster: string
    view?: number
    config: {
        endAt: number
        transAt: number
    }
    order?: number
}

export type StageList = StageItem[]

export const AppChapterState  = reactive<{
    list: StageItem[]
}>({
    list: [] ,
})

