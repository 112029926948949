import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f25108ee"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "video-container"
};
const _hoisted_2 = ["src", "poster"];
const _hoisted_3 = {
  class: "video-container__next"
};
const _hoisted_4 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_next_side = _resolveComponent("next-side");
  const _component_VanButton = _resolveComponent("VanButton");
  const _component_hack_trans = _resolveComponent("hack-trans");
  const _component_video_controls = _resolveComponent("video-controls");
  const _component_ReplayButton = _resolveComponent("ReplayButton");
  const _component_LoadingBar = _resolveComponent("LoadingBar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(`video-container__bg ${_ctx.isTrans ? 'custom-video-effect' : ''}`)
  }, [_createElementVNode("video", {
    preload: "auto",
    playsinline: "",
    "webkit-playsinline": "",
    onClick: _cache[0] || (_cache[0] = () => {
      _ctx.playVideo();
    }),
    onCanplay: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.onVideoReady && _ctx.onVideoReady(...args)),
    ref: "videoRef",
    src: `${_ctx.src}`,
    onEnded: _cache[2] || (_cache[2] =
    //@ts-ignore
    (...args) => _ctx.onVideoEnded && _ctx.onVideoEnded(...args)),
    poster: _ctx.poster ? `${_ctx.poster}?x-oss-process=image/format,jpg/resize,limit_1,w_1280` : '',
    onPlay: _cache[3] || (_cache[3] =
    //@ts-ignore
    (...args) => _ctx.onVideoPlay && _ctx.onVideoPlay(...args)),
    onWaiting: _cache[4] || (_cache[4] =
    //@ts-ignore
    (...args) => _ctx.onVideoWaiting && _ctx.onVideoWaiting(...args)),
    onPause: _cache[5] || (_cache[5] =
    //@ts-ignore
    (...args) => _ctx.onVideoPause && _ctx.onVideoPause(...args)),
    autoplay: ""
  }, null, 40, _hoisted_2)], 2), _createVNode(_component_hack_trans, {
    name: "trans-in-bottom",
    mode: "out-in",
    show: _ctx.isTrans
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_ctx.nextOptions.length ? (_openBlock(), _createBlock(_component_next_side, {
      key: 0,
      "next-options": _ctx.nextOptions
    }, null, 8, ["next-options"])) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_VanButton, {
      size: "small",
      color: "rgb(244, 157, 17)",
      round: "",
      onClick: _ctx.goBack
    }, {
      default: _withCtx(() => [_createTextVNode(" 回到开始 ")]),
      _: 1
    }, 8, ["onClick"])]))])]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_hack_trans, {
    name: "fade-up",
    mode: "out-in",
    show: !_ctx.isTrans
  }, {
    default: _withCtx(() => [_createVNode(_component_video_controls, {
      onPlay: _ctx.handlePlay,
      onPause: _ctx.handlePause,
      onSkip: _ctx.onSkip,
      onSeek: _ctx.onSeek,
      currentTime: _ctx.currentTime,
      duration: _ctx.duration,
      isPlaying: _ctx.isPlaying
    }, null, 8, ["onPlay", "onPause", "onSkip", "onSeek", "currentTime", "duration", "isPlaying"])]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_ReplayButton, {
    show: _ctx.isEnd && !_ctx.disableReplay,
    onClick: _ctx.replay
  }, null, 8, ["show", "onClick"]), _createVNode(_component_hack_trans, {
    name: "fade",
    show: !!_ctx.src && !_ctx.isReady || _ctx.isWaiting
  }, {
    default: _withCtx(() => [_createVNode(_component_LoadingBar)]),
    _: 1
  }, 8, ["show"])]);
}