import { AppVideoPlayerState } from '@/hooks/use-app';
import { defineComponent, onMounted, onUnmounted, reactive, ref, toRef, watch } from 'vue';
import SkipButton from '../skip-button/index.vue';
import ReplayButton from '../replay-button/index.vue';
import LoadingBar from '../loading-bar/index.vue';
import VideoControls from '../video-controls/index.vue';
import NextSide from '@/components/next-side/index.vue';
import { Button } from 'vant';
import { GlobalConfig } from '@/config';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'VideoContainer',
  props: {
    src: {
      type: String,
      default: ''
    },
    poster: {
      type: String,
      default: ''
    },
    endAt: {
      type: Number,
      default: -1
    },
    transAt: {
      type: Number
    },
    disableSkip: {
      type: Boolean,
      default: false
    },
    disableReplay: {
      type: Boolean,
      default: false
    },
    autoNextTo: {
      type: String,
      default: ''
    },
    nextOptions: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SkipButton,
    ReplayButton,
    [Button.name]: Button,
    LoadingBar,
    NextSide,
    VideoControls
  },
  setup(props, {
    emit
  }) {
    const state = reactive({
      isReady: false,
      isPlayStart: false,
      isPlaying: false,
      isWaiting: true,
      isPause: false,
      isEnd: false,
      isTrans: props.src ? false : true,
      transAt: props.transAt,
      endAt: props.endAt
    });
    const videoRef = ref(null);
    const currentTimeRef = ref(0);
    const durationRef = ref(0);
    const router = useRouter();
    watch(() => state.isEnd && state.isTrans, v => {
      if (v && props.autoNextTo) {
        window.setTimeout(() => {
          router.replace(`/stage/${props.autoNextTo}`);
        });
      }
    });
    const onVideoReady = () => {
      console.log('video loaded');
      // emit
      emit('ready', videoRef.value);
      const video = videoRef.value;
      if (video) {
        video.volume = AppVideoPlayerState.volume;
        video.playbackRate = AppVideoPlayerState.playSpeed;
        const duration = video.duration;
        durationRef.value = duration;
        const endAt = state.endAt === -1 ? duration : state.endAt;
        state.endAt = endAt;
        if (state.transAt === undefined || state.transAt === -1) {
          state.transAt = endAt - 1;
        }
        playVideo(video);
      }
      state.isReady = true;
    };
    const timerRef = ref(null);
    const pauseVideo = () => {
      const videoElem = videoRef.value;
      if (videoElem) {
        videoElem.pause();
      }
    };
    const transTimerRef = ref(null);
    const startTrans = () => {
      if (transTimerRef.value) {
        window.clearInterval(transTimerRef.value);
      }
      state.isTrans = true;
      let r = 0;
      const currentVolume = videoRef.value?.volume || 1;
      transTimerRef.value = window.setInterval(() => {
        r += 0.2;
        easeVolume(currentVolume, r);
        if (r >= 1) {
          if (transTimerRef.value) {
            window.clearInterval(transTimerRef.value);
          }
        }
      }, 100);
    };
    const goBack = () => {
      router.replace(`/stage/intro`);
    };
    watch(() => state.isTrans, v => {
      if (!v) {
        return;
      }
      console.log(`video start to trans...`);
      emit('trans');
      startTrans();
    });
    watch(() => state.isEnd, () => {
      if (state.isEnd) {
        pauseVideo();
      }
    });
    watch(() => AppVideoPlayerState.volume, () => {
      const videoElem = videoRef.value;
      if (videoElem) {
        videoElem.volume = AppVideoPlayerState.volume;
      }
    });
    watch(() => AppVideoPlayerState.playSpeed, () => {
      const videoElem = videoRef.value;
      if (videoElem) {
        videoElem.playbackRate = AppVideoPlayerState.playSpeed;
      }
    });
    const playVideo = video => {
      if (state.isEnd || state.isTrans) {
        return;
      }
      const videoElem = video || videoRef.value;
      if (videoElem) {
        videoElem.play().then(() => {
          state.isPlayStart = true;
          state.isPlaying = true;
          state.isPause = false;
          state.isWaiting = false;
        }).catch(e => {
          console.log(e);
        });
      }
    };
    const replay = () => {
      if (!state.isEnd) {
        return;
      }
      const videoElem = videoRef.value;
      if (videoElem) {
        videoElem.currentTime = 0;
        state.isEnd = false;
        state.isTrans = false;
        // state.isPlaying = true;
        window.setTimeout(() => {
          playVideo();
        }, 300);
      }
    };
    const easeVolume = (init, r) => {
      return;
      // const videoElem = videoRef.value;
      // if (videoElem) {
      //     // lerp 
      //     videoElem.volume = init * (1 - r);
      // }
    };

    const updateDuration = () => {
      const videoElem = videoRef.value;
      if (!videoElem) {
        return;
      }
      currentTimeRef.value = videoElem.currentTime;
      if (state.transAt && currentTimeRef.value >= state.transAt) {
        state.isTrans = true;
      }
      if (state.endAt && currentTimeRef.value >= state.endAt) {
        state.isEnd = true;
      }
    };
    const clearTimer = () => {
      if (timerRef.value) {
        window.clearInterval(timerRef.value);
      }
      currentTimeRef.value = 0;
      if (transTimerRef.value) {
        window.clearInterval(transTimerRef.value);
      }
    };
    const onVideoPlay = () => {
      console.log('video play...');
      state.isPlaying = true;
      state.isPause = false;
      state.isWaiting = false;
      if (!timerRef.value) {
        timerRef.value = window.setInterval(() => {
          updateDuration();
        }, 100);
      }
    };
    const onVideoPause = () => {
      console.log('video pause');
      state.isPlaying = false;
    };
    const onVideoWaiting = () => {
      console.log('video waiting');
      state.isPlaying = false;
      state.isWaiting = true;
    };
    const onVideoEnded = () => {
      console.log('video ended');
      state.isEnd = true;
      state.isTrans = true;
      // clearTimer()
    };

    const onSkip = () => {
      console.log('video skip');
      const currentTime = videoRef.value?.currentTime;
      const duration = videoRef.value?.duration;
      if (currentTime === undefined || duration === undefined) {
        state.isEnd = true;
        state.isTrans = true;
        return;
      }
      const transAt = state.transAt || duration - 1;
      if (currentTime > transAt) {
        return;
      }
      if (state.isPlaying) {
        state.transAt = currentTime;
        state.endAt = currentTime + 0.65;
      } else {
        state.isEnd = true;
        state.isTrans = true;
      }
    };
    const onSeek = newTime => {
      const videoElem = videoRef.value;
      if (!videoElem) {
        return;
      }
      // update video current time
      const duration = videoElem.duration;
      if (duration === undefined) {
        return;
      }
      if (newTime < 0) {
        newTime = 0;
      }
      if (newTime > duration) {
        newTime = duration;
      }
      videoElem.currentTime = newTime;
      currentTimeRef.value = newTime;
      videoElem.play().catch(e => {
        console.log(e);
      });
    };
    onUnmounted(() => {
      clearTimer();
    });
    const preventTouchMove = e => {
      e.preventDefault();
    };
    onMounted(() => {
      const doc = document.querySelector('body');
      if (doc) {
        doc.addEventListener('touchmove', preventTouchMove, {
          passive: false
        });
      }
    });
    onUnmounted(() => {
      const doc = document.querySelector('body');
      if (doc) {
        doc.removeEventListener('touchmove', preventTouchMove);
      }
    });
    const handlePlay = () => {
      console.log('handlePlay');
      playVideo();
    };
    const handlePause = () => {
      pauseVideo();
    };
    return {
      onVideoWaiting,
      onVideoReady,
      onVideoEnded,
      onVideoPause,
      goBack,
      handlePlay,
      handlePause,
      // handleVideoTimeUpdate,
      onVideoPlay,
      onSkip,
      onSeek,
      replay,
      playVideo,
      videoRef,
      isEnd: toRef(state, 'isEnd'),
      isTrans: toRef(state, 'isTrans'),
      isPlaying: toRef(state, 'isPlaying'),
      isReady: toRef(state, 'isReady'),
      currentTime: toRef(currentTimeRef, 'value'),
      duration: toRef(durationRef, 'value'),
      mediaBaseUrl: GlobalConfig.mediaBaseUrl,
      isWaiting: toRef(state, 'isWaiting')
    };
  }
});