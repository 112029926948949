import { defineComponent, reactive, onMounted, computed } from 'vue';
import { Button } from 'vant';
import { useRouter } from 'vue-router';
export default defineComponent({
  props: {
    nextOptions: {
      type: Array,
      default: () => []
    }
  },
  components: {
    [Button.name]: Button
  },
  setup(props, {
    emit
  }) {
    const router = useRouter();
    const jump = stage => {
      router.replace(`/stage/${stage.id}`);
    };
    const state = reactive({
      show: false
    });
    onMounted(() => {
      console.log('nextOptions', props.nextOptions);
      state.show = true;
    });
    const show = computed(() => {
      return state.show && props.nextOptions.length > 0;
    });
    return {
      jump,
      show
    };
  }
});