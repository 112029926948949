import "core-js/modules/es.array.push.js";
import { computed, defineComponent, onMounted, reactive, toRef } from 'vue';
import { Button } from 'vant';
import { AppChapterState } from '../../stage';
import StageButton from '../../components/stage-button/index.vue';
import TrackPanel from './panel.vue';
import { AppStageState } from '@/hooks/use-app';
export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 0
    },
    currentTime: {
      type: Number,
      default: 0
    }
  },
  components: {
    StageButton,
    [Button.name]: Button,
    TrackPanel
  },
  setup(props, {
    emit
  }) {
    const state = reactive({
      open: false,
      showTrigger: false
    });
    let currentStage = AppStageState.current;
    const trackList = [];
    while (!!currentStage) {
      const visited = currentStage;
      const unvisited = AppChapterState.list.filter(s => s.pid === currentStage.pid && s.id !== currentStage.id);
      trackList.push({
        visited,
        unvisited
      });
      currentStage = AppChapterState.list.find(s => s.id === currentStage?.pid);
    }
    trackList.pop();
    const enable = computed(() => {
      const v = AppStageState.current?.pid && trackList.length > 1;
      return !!v;
    });
    onMounted(() => {
      window.setTimeout(() => {
        state.showTrigger = true;
      }, 650);
    });
    const toggle = () => {
      state.open = !state.open;
    };
    return {
      trackList: trackList.reverse(),
      open: toRef(state, 'open'),
      showTrigger: toRef(state, 'showTrigger'),
      toggle,
      enable
    };
  }
});