import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-46730976"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home-button-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_stage_button = _resolveComponent("stage-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_stage_button, {
    onClick: _ctx.goHome
  }, {
    default: _withCtx(() => [_createVNode(_component_svg_icon, {
      "icon-class": "home"
    })]),
    _: 1
  }, 8, ["onClick"])]);
}