import { createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home/index.vue'
import StageView from '../views/stage/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/stage/:sid',
    name: 'stage',
    component: StageView
  },
  {
    path: '/page-0',
    name: 'Page0',
    component: () => import(/* webpackChunkName: "page-0" */ '../views/page-0/index.vue')
  },

  {
    path: '/page-a-0',
    name: 'PageA0',
    component: () => import(/* webpackChunkName: "page-A-0" */ '../views/page-a-0/index.vue')
  },
  {
    path: '/page-a-1',
    name: 'PageA1',
    component: () => import(/* webpackChunkName: "page-A-1" */ '../views/page-a-1/index.vue')
  },
  {
    path: '/content',
    name: 'content',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "content-view" */ '../views/content/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory('/xiao-jiang-you/'),
  routes
})

export default router
