import { defineComponent, onMounted, reactive, toRef } from 'vue';
import { useRouter } from 'vue-router';
import BgImage from '@/components/bg-image/index.vue';
import VideoPreload from '@/components/video-preload/index.vue';
import { GlobalConfig } from '@/config';
export default defineComponent({
  name: 'HomeView',
  components: {
    BgImage,
    VideoPreload
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      assetReady: false
    });
    const onPreloadFinish = () => {
      state.assetReady = true;
    };
    const handleClick = () => {
      router.replace('/stage/intro');
    };
    onMounted(() => {
      window.setTimeout(() => {
        state.assetReady = true;
      }, 5000);
    });
    return {
      handleClick,
      onPreloadFinish,
      assetReady: toRef(state, 'assetReady'),
      baseUrl: GlobalConfig.mediaBaseUrl
    };
  }
});