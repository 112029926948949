import "core-js/modules/es.array.push.js";
import { computed, defineComponent } from 'vue';
import RouterTransition from './router-transition.vue';
import SettingsTrigger from '@/components/settings-trigger/index.vue';
import BackButton from '@/components/back-button/index.vue';
import HomeButton from '@/components/home-button/index.vue';
import SkipButton from '@/components/skip-button/index.vue';
import { AppStageState } from '@/hooks/use-app';
import { Button } from 'vant';
import SvgIcon from '@/components/svg-icon/svg-icon.vue';
import { useRouter } from 'vue-router';
import { GlobalConfig } from '@/config';
export default defineComponent({
  name: 'layout-default',
  components: {
    RouterTransition,
    SettingsTrigger,
    HomeButton,
    BackButton,
    SkipButton,
    SvgIcon,
    [Button.name]: Button
  },
  setup() {
    const isVideoStage = computed(() => {
      return AppStageState.current?.type === 'video';
    });
    const isIframeStage = computed(() => {
      return AppStageState.current?.type === 'iframe';
    });
    const router = useRouter();
    const back = () => {
      const current = AppStageState.current;
      if (current) {
        router.replace(`/stage/${current.pid}`);
      }
    };
    const goHome = () => {
      router.push(`/`);
    };
    return {
      isVideoStage,
      isIframeStage,
      back,
      baseUrl: GlobalConfig.mediaBaseUrl,
      goHome
    };
  }
});