import { defineComponent } from 'vue';
import { Button } from 'vant';
import StageButton from '../../components/stage-button/index.vue';
export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      default: 0
    },
    currentTime: {
      type: Number,
      default: 0
    }
  },
  components: {
    StageButton,
    [Button.name]: Button
  },
  setup(props, {
    emit
  }) {
    const onSeek = () => {
      emit('seek');
    };
    const onSkip = () => {
      emit('skip');
    };
    const timeFormatter = time => {
      let minutes = Math.floor(time / 60);
      let seconds = Math.floor(time % 60);
      const minStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const secStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minStr}:${secStr}`;
    };
    return {
      onSkip,
      onSeek,
      timeFormatter
    };
  }
});