import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3e612c27"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "track-trigger-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_stage_button = _resolveComponent("stage-button");
  const _component_hack_trans = _resolveComponent("hack-trans");
  const _component_TrackPanel = _resolveComponent("TrackPanel");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_hack_trans, {
    name: "fade-up",
    show: _ctx.showTrigger && _ctx.enable
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_stage_button, {
      onClick: _ctx.toggle,
      isActive: _ctx.open
    }, {
      default: _withCtx(() => [_createVNode(_component_svg_icon, {
        "icon-class": "foot"
      })]),
      _: 1
    }, 8, ["onClick", "isActive"])])]),
    _: 1
  }, 8, ["show"]), _createVNode(_component_hack_trans, {
    name: "fade-left",
    show: _ctx.open
  }, {
    default: _withCtx(() => [_createVNode(_component_TrackPanel, {
      onClose: _ctx.toggle,
      trackList: _ctx.trackList
    }, null, 8, ["onClose", "trackList"])]),
    _: 1
  }, 8, ["show"])], 64);
}