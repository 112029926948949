import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Transition as _Transition, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1dde3ab5"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "back-button-wrapper"
};
const _hoisted_2 = {
  key: 0,
  class: "ml-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createBlock(_Transition, {
    name: "fade-right",
    mode: "out-in"
  }, {
    default: _withCtx(() => [_ctx.track.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.track, (item, index) => {
      return _openBlock(), _createElementBlock("li", {
        key: item.id,
        class: _normalizeClass(index === _ctx.track.length - 1 ? 'active' : '')
      }, [_createVNode(_component_router_link, {
        to: {
          name: 'stage',
          params: {
            sid: item.id
          }
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.title instanceof Array ? item.title.join('') : item.title) + " ", 1), index !== _ctx.track.length - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_2, " > ")) : _createCommentVNode("", true)]),
        _: 2
      }, 1032, ["to"])], 2);
    }), 128))])])) : _createCommentVNode("", true)]),
    _: 1
  });
}