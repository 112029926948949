import '@/styles/transition.scss'
import '@/styles/tailwind.css'
import '@/styles/global.scss'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { setupCustomComponents } from './plugins/customComponents'
import { createRouterGuards } from './router/guard'
const app = createApp(App)
setupCustomComponents(app)
app.use(router)
createRouterGuards(router)
// 路由准备就绪后挂载APP实例
router.isReady().then(() => app.mount('#app'))
