import { defineComponent } from 'vue';
export default defineComponent({
  name: 'HackTrans',
  props: {
    // Component: {
    //     type: Object,
    //     default: null
    // },
    show: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'fade'
    },
    mode: {
      type: String,
      default: 'out-in'
    }
  },
  setup() {
    const onAfterEnter = el => {
      console.log('HackTrans: onAfterEnter');
      el.classList.add('hack');
    };
    const onBeforeLeave = el => {
      console.log('HackTrans: onBeforeLeave');
      el.classList.remove('hack');
    };
    return {
      onAfterEnter,
      onBeforeLeave
    };
  }
});