import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-61d105ee"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "iframe-container"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 0,
  class: "loading-mask"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_loading_bar = _resolveComponent("loading-bar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("iframe", {
    src: _ctx.url,
    onLoad: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.onPageLoad && _ctx.onPageLoad(...args))
  }, null, 40, _hoisted_2), _createVNode(_Transition, null, {
    default: _withCtx(() => [_ctx.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_loading_bar)])) : _createCommentVNode("", true)]),
    _: 1
  })]);
}