import { computed, defineComponent, onUnmounted, reactive, toRef, watch } from 'vue';
import { Button, Switch } from 'vant';
import StageButton from '../../components/stage-button/index.vue';
import { Slider } from 'vant';
import { AppVideoPlayerState, useAppConfig } from '../../hooks/use-app';
export default defineComponent({
  components: {
    StageButton,
    [Button.name]: Button,
    [Slider.name]: Slider,
    [Switch.name]: Switch
  },
  setup() {
    const state = reactive({
      open: false
    });
    watch(() => AppVideoPlayerState.isMute, v => {
      if (v) {
        AppVideoPlayerState.volume = 0;
      } else {
        AppVideoPlayerState.volume = 0.5;
      }
    });
    watch(() => AppVideoPlayerState.volume, v => {
      if (v > 0) {
        if (AppVideoPlayerState.isMute) {
          AppVideoPlayerState.isMute = false;
        }
      } else {
        AppVideoPlayerState.isMute = true;
      }
    });
    const smartPlaySpeedText = computed(() => {
      const v = AppVideoPlayerState.playSpeed.toFixed(2);
      const lastChar = v[v.length - 1];
      if (lastChar === '0') {
        return v.slice(0, v.length - 1);
      } else {
        return v;
      }
    });
    const closePanel = () => {
      state.open = false;
      document.removeEventListener('click', closePanel);
    };
    onUnmounted(() => {
      document.removeEventListener('click', closePanel);
    });
    const togglePanel = () => {
      console.error('toggle');
      state.open = !state.open;
      // if (state.open) {
      //     // watch click
      //     window.setTimeout(() => {
      //         document.addEventListener('click', closePanel)
      //     })
      // }
    };

    const playSpeedOptions = [{
      label: '0.5',
      value: 0.5
    }, {
      label: '0.75',
      value: 0.75
    }, {
      label: '1',
      value: 1
    }, {
      label: '1.25',
      value: 1.25
    }, {
      label: '1.5',
      value: 1.5
    }, {
      label: '2',
      value: 2
    }];
    const toggleMute = () => {
      setMute(!AppVideoPlayerState.isMute);
    };
    const {
      setVolume,
      setMute,
      setPlaySpeed
    } = useAppConfig();
    return {
      togglePanel,
      toggleMute,
      open: toRef(state, 'open'),
      volume: toRef(AppVideoPlayerState, 'volume'),
      isMute: toRef(AppVideoPlayerState, 'isMute'),
      playSpeed: toRef(AppVideoPlayerState, 'playSpeed'),
      setVolume,
      setMute,
      setPlaySpeed,
      smartPlaySpeedText,
      playSpeedOptions
    };
  }
});