import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-193ef162"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "layout-trans-wrapper"
};
const _hoisted_2 = {
  class: "page-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(), _createBlock(_component_router_view, {
    key: _ctx.notNeedKey ? 'key' : _ctx.route.fullPath
  }, {
    default: _withCtx(({
      Component
    }) => [_ctx.animate ? (_openBlock(), _createBlock(_Transition, {
      key: 0,
      name: "zoom-fade",
      mode: "out-in",
      appear: ""
    }, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
      _: 2
    }, 1024)) : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
      key: 1
    }))]),
    _: 1
  }))])]);
}