import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5c81656a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "stage-button-wrapper"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "stage-button__text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _ctx.show ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nextOptions, item => {
    return _openBlock(), _createElementBlock("div", {
      key: item.id,
      class: "stage-button",
      onClick: () => _ctx.jump(item)
    }, [_createElementVNode("div", _hoisted_3, [item.title instanceof Array ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList(item.title, t => {
      return _openBlock(), _createElementBlock("div", {
        key: t,
        class: "stage-button__text__item"
      }, _toDisplayString(t), 1);
    }), 128)) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [_createTextVNode(_toDisplayString(item.title), 1)], 64))])], 8, _hoisted_2);
  }), 128))])) : _createCommentVNode("", true);
}