import { App } from 'vue'

import { SvgIcon } from '@/components/svg-icon'
import VideoContainer from '@/components/video-container/index.vue'
import HackTrans from '@/components/hack-trans/index.vue'

/**
 * 全局注册自定义组件
 * @param app
 */
export function setupCustomComponents(app: App) {
    app.component(SvgIcon.name, SvgIcon)
    // app.component(VideoContainer.name, VideoContainer),
    app.component(HackTrans.name, HackTrans)
}
