import { getChapterList } from "@/api/app"
import { AppChapterState, StageItem } from "@/stage"
import { Router } from "vue-router"

export function createRouterGuards(router: Router) {
    router.beforeEach((to, from, next) => {
        if (to.path === '/error') {
            next()
            return
        }
        if (AppChapterState.list.length === 0) {
            getChapterList().then((list: StageItem[]) => {
                AppChapterState.list = list
                next()
            }).catch(() => {
                next('/error')
            })
        } else {
            next()
        }

    })

    router.afterEach((to, from, failure) => {
 
    })

    router.onError(error => {
        console.log(error, '路由错误')
    })
}
