import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Transition, {
    name: _ctx.name,
    onAfterEnter: _ctx.onAfterEnter,
    onBeforeLeave: _ctx.onBeforeLeave
  }, {
    default: _withCtx(() => [_ctx.show ? _renderSlot(_ctx.$slots, "default", {
      key: 0
    }, undefined, true) : _createCommentVNode("", true)]),
    _: 3
  }, 8, ["name", "onAfterEnter", "onBeforeLeave"]);
}