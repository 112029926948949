import { StageItem } from "@/stage";
import { reactive } from "vue";

export const AppVideoPlayerState  = reactive({
    volume: 0.75,
    playSpeed: 1,
    isMute: false,
})

export const AppStageState = reactive<{
    current: StageItem | undefined
}>({
    current: undefined
})



export const AppViewState = reactive<{
    deviceDirection: 'portrait' | 'landscape'
}>({
    deviceDirection: 'portrait'
})

export function useAppConfig() {

    const setVolume  = (volume: number) => {
        AppVideoPlayerState.volume = volume
    }

    const setPlaySpeed = (playSpeed: number) => {
        AppVideoPlayerState.playSpeed = playSpeed
    }

    const setMute = (isMute: boolean) => {
        AppVideoPlayerState.isMute = isMute
    }


    return {
        setVolume,
        setPlaySpeed,
        setMute
    }
}