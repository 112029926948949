import { defineComponent, reactive, toRef } from 'vue';
import LoadingBar from '../loading-bar/index.vue';
export default defineComponent({
  name: 'VideoContainer',
  props: {
    stage: {
      type: Object
    }
  },
  components: {
    LoadingBar
  },
  setup(props, {
    emit
  }) {
    const url = props.stage?.url;
    const state = reactive({
      isLoading: true
    });
    const onPageLoad = () => {
      state.isLoading = false;
    };
    return {
      url,
      isLoading: toRef(state, 'isLoading'),
      onPageLoad
    };
  }
});