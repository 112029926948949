import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { AppChapterState } from '@/stage';
export default defineComponent({
  props: {
    loadTotal: {
      type: Boolean,
      default: false
    },
    sid: {
      type: String,
      default: ''
    }
  },
  components: {},
  setup(props, {
    emit
  }) {
    const sid = props.sid;
    const urls = AppChapterState.list.filter(item => item.pid === sid).filter(item => item.type === 'video' && !!item.url).map(item => item.url);
    const videoListRef = ref([]);
    onMounted(() => {
      let count = 0;
      console.log('preload', urls);
      videoListRef.value = urls.map(url => {
        const video = document.createElement('video');
        video.src = `${url}`;
        video.preload = 'auto';
        video.muted = true;
        video.volume = 0;
        const eventName = props.loadTotal ? 'canplaythrough' : 'canplay';
        const onVideoCanPlay = () => {
          console.log('onVideoCanPlay');
          video.removeEventListener(eventName, onVideoCanPlay);
          video.src = '';
          count++;
          if (count === urls.length) {
            emit('finish');
          }
        };
        video.addEventListener(eventName, onVideoCanPlay);
        video.load();
        return video;
      });
      console.log('videoListRef', videoListRef.value);
    });
    onUnmounted(() => {
      videoListRef.value.forEach(video => {
        // stop loading
        video.src = '';
        video.remove();
      });
      videoListRef.value = [];
    });
  }
});