import { defineComponent } from 'vue';
import { Button } from 'vant';
export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    [Button.name]: Button
  },
  setup(props, {
    emit
  }) {}
});