import "core-js/modules/es.array.push.js";
import { defineComponent, onMounted, reactive, toRef, watch } from 'vue';
import { Button } from 'vant';
import { useRoute, useRouter } from 'vue-router';
import { AppChapterState } from '../../stage';
import StageButton from '../../components/stage-button/index.vue';
import { AppStageState } from '@/hooks/use-app';
export default defineComponent({
  components: {
    StageButton,
    [Button.name]: Button
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      track: []
    });
    const updateTrack = () => {
      const sid = route.params.sid;
      state.track = [];
      const stage = AppChapterState.list.find(s => s.id === sid);
      if (stage) {
        state.track.push(stage);
        let currentStage = stage;
        while (currentStage && currentStage.pid) {
          const parentNode = AppChapterState.list.find(s => s.id === currentStage.pid);
          if (!parentNode) {
            break;
          }
          state.track.push(parentNode);
          currentStage = parentNode;
        }
      }
      state.track = state.track.reverse();
      state.track.shift();
    };
    onMounted(() => {
      updateTrack();
    });
    watch(() => route.fullPath, () => {
      updateTrack();
    }, {
      immediate: true
    });
    const track = [];
    const back = () => {
      const current = AppStageState.current;
      if (current) {
        router.replace(`/stage/${current.pid}`);
      }
    };
    return {
      back,
      track: toRef(state, 'track')
    };
  }
});