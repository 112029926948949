import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
export default defineComponent({
  name: 'router-transition',
  props: {
    notNeedKey: {
      type: Boolean,
      default: false
    },
    animate: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  setup() {
    const route = useRoute();
    const isNinjaTiger = computed(() => {
      return route.name === 'ninja-tiger';
    });
    return {
      route,
      isNinjaTiger
    };
  }
});