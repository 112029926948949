import { defineComponent, computed } from 'vue';
//@ts-ignore
const importAll = requireContext => {
  const keys = requireContext.keys();
  // console.log('keys', keys)
  keys.forEach(requireContext);
};
try {
  //@ts-ignore
  importAll(require.context('@/assets/icons', true, /\.svg$/));
} catch (error) {
  console.error(error);
}
export default defineComponent({
  name: 'svg-icon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const iconName = computed(() => `#icon-${props.iconClass}`);
    const svgClass = computed(() => 'svg-icon');
    return {
      iconName,
      svgClass
    };
  }
});