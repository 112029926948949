import { StageItem } from '@/stage'
import request from 'axios'


export async function getChapterList(): Promise<StageItem[]> {
  const res = await  request.request({
    baseURL: process.env.VUE_APP_API_URL,
    method: 'GET',
    url: 'web/chapter',
  }) as StageItem[]

  //@ts-ignore
  return res.data.result.map((chapter: any) => {
    return  {
        ...chapter,
        order: 1,
        config: {
            endAt: -1,
            transAt: -1
        },
        title: chapter.title.split(' ').length > 1 ? chapter.title.split(' ') : chapter.title,
        id: chapter.key ? chapter.key.toLowerCase() : '',
        pid: chapter.parentKey ? chapter.parentKey.toLowerCase()  : '',
        url:  chapter.video || chapter.link,
    }
  })
}



export async function viewChapter(key: string): Promise<any> {
     return await request.request({
        baseURL: process.env.VUE_APP_API_URL,
        method: 'GET',
        url: `web/chapter/view/${key}`,
      }) 
}